<template>
  <div class="box">
    <div>
      <BaaSGovernanceMenu />
    </div>
    <div class="box-governance">
      <router-view />
    </div>
  </div>
</template>

<script>
import { BaaSGovernanceMenu } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSGovernanceMenu,
  },
};
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.box-governance {
  display: flex;
  flex: 1;
}
</style>
